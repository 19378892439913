import {Box, Button, Card, CardActions, CardContent, Chip, IconButton, Link, Typography, useMediaQuery} from '@mui/material';
import './cardJob.css'
import { Accessibility, Dashboard, LinkedIn, Paid, Place, ShareLocation, ShareSharp } from '@mui/icons-material';
import { jobNumberToUrl, separeArray, stringToUrl, titleStringJobToUrl } from '../../services/serviceslocal';
import { NavLink, useNavigate } from 'react-router-dom';
import ButtonAction from '../buttonAction/ButtonAction';

export default function CardJob({item}) {
    const priority = item.Today_s_Priority__c

    const max400 = useMediaQuery("(max-width:400px)");
    const navigate = useNavigate()

    const urlJob = `../find-a-job/jobs/job?title=${titleStringJobToUrl(item.Title_Website)}&id=${jobNumberToUrl(item.TR1__Job_Number__c)}`
    const goToJob = () => {
        navigate(`../find-a-job/jobs/job?title=${titleStringJobToUrl(item.Title_Website)}&id=${jobNumberToUrl(item.TR1__Job_Number__c)}`)
        // navigate(`../find-a-job/jobs/job?title=${stringToUrl(item.Title_Website)}&id=${jobNumberToUrl(item.TR1__Job_Number__c)}`)
        // navigate("../find-a-job/jobs/" + stringToUrl(item.Title_Website) + jobNumberToUrl(item.TR1__Job_Number__c))
    }
    return (
        <Card className={`cardJob  col w100`} sx={{my:0.5, p:1}}>
            <CardContent className="w100" sx={{position:"relative"}}>
                {/* <Box className=" cardJob_title flex w100 aicenter" onClick={() =>goToJob()}> */}
                <NavLink to={urlJob} className="cardJob_title flex w100 aicenter">  
                    <Typography gutterBottom variant="h5" color="primary" component="h2">
                        {item.Title_Website}
                    </Typography>
                    <Typography noWrap gutterBottom variant="body1" component="span" className="tl">
                        {item.TR1__Job_Number__c}
                    </Typography>
                </NavLink>

                <Box className="flex row w100 wrap ">
                    {
                        separeArray([item.TR1__State_Area__c]).sort().map(element => {
                            return(
                                <Button 
                                    key={element}
                                    sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                                    color="secondary"                
                                >
                                    <Chip size="small"  icon={<Place />} label={`${element}`}/>
                                </Button>
                            )
                        })
                
                    }
                    {
                        separeArray([item.TR1__City__c]).sort().map(element => {
                            return(
                                <Button 
                                    key={element}
                                    sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                                    color="secondary"                
                                >
                                    <Chip size="small"  icon={<Place />} label={`${element}`}/>
                                </Button>
                            )
                        })
                
                    }
                    {
                        separeArray([item.Country__c]).sort().map(element => {
                            return(
                                <Button 
                                    key={element}
                                    sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                                    color="secondary"                
                                >
                                    <Chip size="small"  icon={<Place />} label={`${element}`}/>
                                </Button>
                            )
                        })
                
                    }
                    {item.Type_of_work__c && 
                        <Button 
                            sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                            color="secondary"                
                        >
                            <Chip size="small"  icon={<ShareLocation />} label={item.Type_of_work__c}/>
                        </Button>
                    }
                </Box>

                <Box className="flex row w100 wrap ">
                    {item.TR1__Industry__c &&
                        <Button 
                            sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                            color="secondary"                
                        >
                            <Chip size="small" icon={<Dashboard />} label={item.TR1__Industry__c}/>
                        </Button>
                    }
                    {item.TR1__Division__c &&
                        <Button 
                            sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                            color="secondary"                
                        >
                            <Chip  size="small"icon={<Accessibility />} label={item.TR1__Division__c}/>
                        </Button>
                    }
                </Box>

                {item.Post_Salary__c &&
                    <Box className="flex row w100 wrap ">
                        <Button 
                            sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                            color="secondary"                
                        >
                            <Chip size="small" icon={<Paid />} label={item.TR1__Salary_Low__c  + " USD"}/>
                        </Button>

                        <Button 
                            sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                            color="secondary"                
                        >
                            <Chip  size="small"icon={<Paid />} label={item.TR1__Salary_High__c  + " USD"}/>
                        </Button>
                    </Box>
                }
                
            </CardContent>
            <CardActions className="w100">
                {item.LinkedIn_Post_URL__c &&
                    <Box
                        style={{borderRadius:"50%", width: "fit-content", height: "fit-content", marginRight:"auto"}}
                    >
                        <Link
                            href={item.LinkedIn_Post_URL__c}
                            underline="none" target="_blank"
                        >
                            {max400? 
                                <IconButton
                                    size="small"
                                    sx={{m:0, color:"#115AB0"}}
                                    // onClick={() => {onMessage()}}
                                >
                                    <LinkedIn/>
                                </IconButton>
                            :
                            <Button
                                size="small"
                                color='linkedin'
                                // variant="contained"
                                // sx={{m:0, color:"var(--white)", borderRadius:"50px", px:2}}
                                variant="text"
                                sx={{m:0, color:"var(--linkedin)", borderRadius:"50px"}}
                                endIcon={<LinkedIn/>}
                            >
                                <Typography  sx={{fontSize:"13px", textTransform:"none"}} noWrap  component="span" >
                                    Apply on
                                </Typography>
                            </Button>
                            }
                        </Link> 
                    </Box>
                }

                <CardActions  sx={{marginLeft:"auto"}}>
                    <NavLink to={urlJob}>
                        <Button 
                            variant='text' 
                            style={{textTransform:"none", borderRadius:"50px"}} 
                            // onClick={()=> goToJob()}
                            >
                            View Job
                        </Button>
                    </NavLink>

                    <ButtonAction
                        variant='contained'
                        sx={{textTransform:"none", borderRadius:"50px"}}
                        titleJob={item.Title_Website}
                        numberJob={item.TR1__Job_Number__c}
                        idJob={item.Id} 
                        odQ1 = {item.Opportunity_Discussed_Question_1__c}
                        odQ2 = {item.Opportunity_Discussed_Question_2__c}
                        odQ3 = {item.Opportunity_Discussed_Question_3__c}
                        action="open:form-talent"
                        translate="no"
                    >
                        Apply ASAP 
                    </ButtonAction>
                </CardActions>

            </CardActions>


        </Card>
    );
}